import React from "react";
import { createRoot } from 'react-dom/client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient();

import { Controller } from "@hotwired/stimulus";

import { RestaurantStatus, RestaurantStatusButton } from "src/admin/components/restaurant_status_button";
import { RestaurantPrepButton } from "src/admin/components/restaurant_prep_button";

export default class RestaurantShowController extends Controller {
  static targets = ["statusButton", "prepButton"];

  static values = {
    "initialState": String,
    "initialInactiveUntil": String,
  };

  declare readonly initialStateValue: string // Expecting something from RestaurantStatusButton::RestaurantStatus
  declare readonly initialInactiveUntilValue: string; // Expecting ISO8601

  declare statusButtonTarget : HTMLButtonElement;
  declare prepButtonTarget : HTMLButtonElement;

  connect() {
    const initialInactiveUntil = new Date(this.initialInactiveUntilValue);

    if (!this.isValidStatus(this.initialStateValue)) {
      throw "";
    }

    const statusButtonRoot = createRoot(this.statusButtonTarget);
    statusButtonRoot.render(
      <QueryClientProvider client={queryClient}>
        <RestaurantStatusButton initialStatus={this.initialStateValue} initialInactiveUntil={initialInactiveUntil} />
      </QueryClientProvider>
    );

    const prepButtonRoot = createRoot(this.prepButtonTarget);
    prepButtonRoot.render(React.createElement(RestaurantPrepButton, {}));
  }

  isValidStatus(inState: string): inState is RestaurantStatus {
    return ["active", "inactive", "disabled", "removed"].includes(inState);
  }
}
