import { Controller } from "@hotwired/stimulus";
import React, { useCallback, useState } from 'react'
import { createRoot } from "react-dom/client";

import { ReactTags } from 'react-tag-autocomplete'
import { matchSorter } from 'match-sorter'
import type { Tag, TagSuggestion } from 'react-tag-autocomplete'

function ProductFilterSelector(props: { inputName: string; allTags: Tag[]; initialTags: Tag[] }) {
  const [selected, setSelected] = useState<Tag[]>(props.initialTags)

    const onAdd = useCallback(
      (newTag: Tag) => { setSelected([...selected, newTag]) },
      [selected]
    )

    const onDelete =
      useCallback(
        (tagIndex: number) => { setSelected(selected.filter((_, i) => i !== tagIndex)) },
        [selected]
      )

    const styles = {
      root: '',
      rootIsActive: 'is-active',
      rootIsDisabled: 'is-disabled',
      rootIsInvalid: 'is-invalid',
      label: 'is-hidden',
      tagList: 'tags',
      tagListItem: '',
      tag: 'tag is-light',
      tagName: '',
      comboBox: 'react-tags__combobox',
      input: 'input',
      listBox: 'list has-hoverable-list-items',
      option: 'list-item',
      optionIsActive: 'has-text-weight-bold',
      highlight: 'react-tags__listbox-option-highlight',
    }

    const suggestionsTransform = (value: string, suggestions: TagSuggestion[]) => {
      return matchSorter(suggestions, value, { keys: ['label'] }).slice(0, 10)
    }

  return (
      <div>
      <ReactTags
        labelText={"Applicable product tags"}
        selected={selected}
        suggestions={props.allTags}
        onAdd={onAdd}
        onDelete={onDelete}
        allowNew={true}
        allowResize={false}
        noOptionsText={"No matching filters"}
        classNames={styles}
        suggestionsTransform={suggestionsTransform}
        collapseOnSelect={true}
      />
      <input key={"blank_input_to_let_us_submit_an_empty_array"} name={props.inputName} hidden readOnly type="text" value="" />
      {selected.map((tag) => (
        <input key={tag.value?.toString()} name={props.inputName} hidden readOnly type="text" value={tag.value?.toString()} />
      ))}
      </div>
      )
}

export default class extends Controller {
  static values = {
    objectName: String,
    propertyName: String,
    initialFilterTags: Array,
    allFilterTags: Array
  }

  declare initialFilterTagsValue : Array<Tag>
  declare allFilterTagsValue : Array<Tag>
  declare objectNameValue : string
  declare propertyNameValue : string

  async connect() {
    const root = createRoot(this.element);

    root.render(
      React.createElement(ProductFilterSelector, {
        inputName: `${this.objectNameValue}[${this.propertyNameValue}][]`,
        initialTags: this.initialFilterTagsValue,
        allTags: this.allFilterTagsValue,
      })
    );
  }
}
