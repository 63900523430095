import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { classNames } from "src/common_util";

import { DropdownButton } from "./dropdown_button";

export type RestaurantStatus = "active" | "inactive" | "disabled" | "removed";
type InactiveUntilStatus = "inactive_until_future_date" | "inactive_until_eod" | "inactive_until";

interface StatusButtonProps {
  initialStatus: RestaurantStatus;
  initialInactiveUntil: Date;
}

type CombinedState = RestaurantStatus | InactiveUntilStatus
enum ModalState {
  StatusModalVisible,
  NoModalVisible,
}

const RestaurantStatusButton = ({
  initialStatus,
  initialInactiveUntil,
}: StatusButtonProps) : JSX.Element => {
  const timeFormatter = new Intl.DateTimeFormat("en-UK", { timeStyle: "short" });
  const [inactiveUntil, setInactiveUntil] = useState<Date>(initialInactiveUntil);
  const [inactiveStyle, setInactiveStyle] = useState<string>("period");
  const [inactivePeriod, setInactivePeriod] = useState<number>(30);
  const [now, setNow] = useState<Date>(new Date());
  const [modalState, setModalState] = useState<ModalState>(ModalState.NoModalVisible);

  const statusQuery = useQuery<string>({
    queryKey: ["status"],
    queryFn: async () => {
      return initialStatus;
    },
    initialData: initialStatus,
  });

  useEffect(() => {
    const handle = window.setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => window.clearInterval(handle);
  }, [setNow]);

  const combinedStateActive = useMemo(() => {
    const eodToday : number = new Date(now.getTime()).setHours(23, 59, 59, 999);
    const dayDuration : number = 24 * 60 * 60 * 1000;
    const inactiveUntilMillis = inactiveUntil.getTime();
    const delta = inactiveUntilMillis - now.getTime();

    if (delta > eodToday + dayDuration)
      return "inactive_until_future_date";
    else if (delta > eodToday)
      return "inactive_until_eod";
    else if (delta > 0)
      return "inactive_until";
    else
      return "active";
  }, [now, inactiveUntil]);

  const combinedState = useMemo(() => {
    switch (statusQuery.data) {
      case "active":
        return combinedStateActive;

      default:
        return statusQuery.data as RestaurantStatus;
    }
  }, [statusQuery.data, combinedStateActive]);

  const statusToButtonLabel = (state: CombinedState) : string => {
    switch (state) {
      case "active":
        return "Active";

      case "inactive_until_future_date":
        return `Inactive until ${timeFormatter.format(inactiveUntil)}`;

      case "inactive_until_eod":
        return "Inactive for today";

      case "inactive_until":
        return `Inactive until ${timeFormatter.format(inactiveUntil)}`;

      case "inactive":
        return "Inactive";

      case "disabled":
        return "Disabled";

      case "removed":
        return "Removed";
    }
  };

  const statusToButtonStyles = (state: CombinedState): Record<string, boolean> => {
    const cname = (() => {
      switch (state) {
        case "active":
          return "is-success";

        case "inactive_until_future_date":
          return "is-link";

        case "inactive_until_eod":
          return "is-link";

        case "inactive_until":
          return "is-link";

        case "inactive":
          return "is-error";

        case "disabled":
          return "";

        case "removed":
          return "";
      }
    })();

    return { [cname]: true, "is-light": true, "is-outlined": true };
  }

  const statusToButtonIcon = (state: CombinedState): string => {
    switch (state) {
      case "active":
        return "fas fa-check-circle";

      case "inactive_until_future_date":
      case "inactive_until_eod":
      case "inactive_until":
        return "fas fa-alarm-snooze";

      case "inactive":
        return "fas fa-moon";

      case "disabled":
        return "fas fa-disabled";

      case "removed":
        return "fas fa-removed";
    }
  }

  const button = <>
    <span className="icon">
      <i className={statusToButtonIcon(combinedState)} aria-hidden="true"></i>
    </span>

    <span>{statusToButtonLabel(combinedState)}</span>

    <span className="icon">
      <i className="fas fa-chevron-down" aria-hidden="true"></i>
    </span>
  </>;

  const onSetInactiveClick = useCallback(() => {
    setModalState(ModalState.StatusModalVisible);
  }, []);

  const menuContent = <>
    <a className="dropdown-item" onClick={onSetInactiveClick}>
      Set inactive
    </a>
  </>;

  const timeOptions = [10, 20, 30, 60, 90, 120, 150, 180, 210, 240];
  const timeOptionLabel = (time: number) => {
    if (time > 90 && (time / 60) != Math.floor(time / 60))
      return `${Math.floor(time / 60)} hours ${time - (Math.floor(time / 60) * 60)} mins`;
    else if (time > 90)
      return `${Math.floor(time / 60)} hours`;
    else if (time == 90)
      return `1 hour 30 minutes`;
    else if (time < 60)
      return `${time} minutes`;
    else
      return `1 hour`;
  };

  const onActivePeriodChange = ((event: React.ChangeEvent) => {
    const el = event.target as HTMLInputElement;
    setInactivePeriod(Number.parseInt(el.value));
  });

  const dismissModal = useCallback(() => {
    setModalState(ModalState.NoModalVisible);
  }, []);

  const inactivePeriodModalContent = (<div className="control">
    <label className="label">
      Period
    </label>

    <div className="select is-fullwidth">
      <select onChange={onActivePeriodChange} value={inactivePeriod}>
        {timeOptions.map(t => (
          <option value={t} key={t}>
            {timeOptionLabel(t)}
          </option>
        ))}
      </select>
    </div>
  </div>);

  const onInactiveUntilChange = useCallback((event: React.ChangeEvent) => {
    const el = event.target as HTMLInputElement;

    if (el.valueAsDate != null)
      setInactiveUntil(el.valueAsDate);
  }, [setInactiveUntil]);

  const inactiveUntilModalContent = <>
    <label className="label">
     Date
    </label>

    <input type="date" className="input" onChange={onInactiveUntilChange} value={inactiveUntil.toISOString().split("T")[0]} />
  </>;

  const currentStatusModalContent = inactiveStyle == "period" ? inactivePeriodModalContent : inactiveUntilModalContent;

  const inactiveStyleChanged = useCallback((event: React.ChangeEvent) => {
    const el = event.target as HTMLInputElement;
    setInactiveStyle(el.value);
  }, [setInactiveStyle]);

  const statusModelClasses = classNames({
    "modal": true,
    "is-active": modalState == ModalState.StatusModalVisible,
  })

  const statusModal = <div className={statusModelClasses}>
    <div className="modal-background" onClick={dismissModal}></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Deactivate store</p>
        <button className="delete" aria-label="close" onClick={dismissModal}></button>
      </header>

      <section className="modal-card-body">
        <input type="radio" name="inactive-style" value="period" id="inactive-style-period" checked={inactiveStyle == "period"} onChange={inactiveStyleChanged} />
        <label htmlFor="inactive-style-period" className="ml-2">For a set period</label>

        <input type="radio" name="inactive-style" value="until" id="inactive-style-until" checked={inactiveStyle == "until"} onChange={inactiveStyleChanged} className="ml-5" />
        <label htmlFor="inactive-style-until" className="ml-2">Choose date and time</label>

        <div className="mt-5">
          {currentStatusModalContent}
        </div>
      </section>

      <footer className="modal-card-foot is-flex-direction-row-reverse">
        <button className="button is-primary">Deactivate</button>
        <button className="button mr-2" onClick={dismissModal}>Cancel</button>
      </footer>
      <button className="modal-close is-large" aria-label="close" onClick={dismissModal}></button>
    </div>
  </div>;

  return <>
    {statusModal}

    <DropdownButton
      button={button}
      buttonClasses={statusToButtonStyles(combinedState)}
      menuContent={menuContent}
    />
  </>;

}

export { RestaurantStatusButton }
